export const CompetitionConstants = {
  TOP_COMPETITIONS_IDS: [68, 7, 61, 43, 54] as number[],
  BETTING_COMPETITIONS_IDS: [68, 17, 12] as number[],
  NAV_BAR_COMPETITIONS_IDS: {
    championship: [68, 69, 7, 61, 43, 54] as number[],
    cup: [12, 79, 167] as number[],
    international: [158, 184] as number[],
  },
  LIVE_COMPETITIONS: [
    {
      id: 158, // nations league
      priority: 1,
      groups: [13264, 13263, 13265, 13266, 13267, 13270, 13268, 13269, 13262, 13261, 13259, 13260, 13257, 13258],
    },
    {
      id: 68, // Serie A
      priority: 1,
    },
    {
      id: 17, //Coppa Italia
      priority: 2,
    },
    {
      id: 73, // Supercoppa
      priority: 3,
    },
    {
      id: 12, // Champions League
      priority: 11,
    },
    {
      id: 69, // Serie B
      priority: 4,
    },
    {
      id: 145, // Serie C
      priority: 5,
    },
    {
      id: 54, // Premier League
      priority: 7,
    },
    {
      id: 61, // LaLiga
      priority: 8,
    },
    {
      id: 7, // Bundesliga
      priority: 9,
    },
    {
      id: 43, // Ligue 1
      priority: 10,
    },
    {
      id: 79, // Europa League
      priority: 12,
    },
    {
      id: 167, // Conference League
      priority: 13,
    },
  ] as {
    id: number
    priority: number
    groups?: number[]
  }[],
} as const
